import './landing_page.scss';
export default function landing_page() {
    return (
        <section className='landing_page container' id='landing_page'>

            <div className='main'>
                <h3 className='title' data-aos='sm-fade-up'>
                    Abdallah Soliman
                </h3>

                <h5
                    className='subtitle'
                    data-aos='sm-fade-down'
                    data-aos-delay='300'
                >
                    Software Engineer
                </h5>

                <p className='description animate__animated animate__fadeIn'>
                    A Computer Scientist developing from the back to the front
                    end.
                </p>


                <div className='btn-group animate__animated animate__fadeIn'>
                    <a
                        className='link'
                        href='https://drive.google.com/file/d/13WmrvOtN7_qpT6IDK8hmKR5-Woak6JWX/view?usp=sharing'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Resume
                        <i className='fas fa-chevron-right' />
                    </a>
                    <button
                        className='link'
                        onClick={
                            () => {
                                scrollTo('projects_page')
                            }
                        }
                    >
                        Learn more
                        <i className='fas fa-chevron-right' />
                    </button>
                </div>
                <div className='image animate__animated animate__fadeIn' />
            </div>

        </section>
    );
}

function scrollTo(elem) {
    document.getElementById(elem).scrollIntoView(true);
    const navContainer = document.querySelector('.nav-container');
    navContainer.classList.toggle('active');
}