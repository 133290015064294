import './about_page.scss';

export default function about_page() {
    return (
        <section className='about_page container' id='about_page'>
            <div className='main'>

                <div className='paragraph'>
                    <h2
                        className='title'
                        data-aos='fade'
                    >
                        Hello, World!
                    </h2>
                    <br />
                    <p className='paragraph-text' data-aos='fade'>
                        My name is Abdallah. I am a software engineer near the
                        Washington DC-Baltimore area. I thrive on solving
                        complex problems and crafting elegant solutions in
                        both the front and back end. My journey has led me to
                        fast-paced scrum team following the agile methodology.
                        My passions are all about computer vision, artificial
                        intelligence, and web development. I find constant
                        inspiration in pushing the boundaries of innovation.
                        <br /><br />
                        Currently seeking new opportunities.
                        Check out my&nbsp;
                        <a
                            href='https://drive.google.com/file/d/13WmrvOtN7_qpT6IDK8hmKR5-Woak6JWX/view?usp=sharing'
                            className='link'
                            target='_blank'
                            rel='noreferrer'
                        >
                            resume
                        </a>
                        &nbsp;for more info.
                    </p>
                    <div className='image' data-aos='fade-long' />
                </div>
            </div>
        </section>
    );
}