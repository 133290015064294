import React from 'react';

import './index.scss';

import NAVBAR from './components/navbar/navbar'


import LOADING_SCREEN from './components/loading_screen/loading_screen';

import LANDING_PAGE from './pages/landing_page/landing_page';
import PROJECTS_PAGE from './pages/projects_page/projects_page';
import ABOUT_PAGE from './pages/about_page/about_page';
import CONTACT_PAGE from './pages/contact_page/contact_page';

export default class main_page extends React.Component{

    constructor(props) {
        super(props);
        this.state = {isLoading: true}
    }

    componentDidMount(){
        setTimeout( () => {
            this.setState({isLoading: false});
        },
        500
        );
        
    }


    render() {
        return(
            this.state.isLoading ? <LOADING_SCREEN/> : 
            <div className='wrapper'>
                <NAVBAR/>
                <LANDING_PAGE/>
                <PROJECTS_PAGE/>
                <ABOUT_PAGE/>
                <CONTACT_PAGE/>
            </div>
        );
    }
    
}