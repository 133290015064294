import React from 'react';
import { Link } from 'react-router-dom';
import './projects_page.scss';


export default function projects_page (){
    return (
        <div className='projects_page container' id = 'projects_page'>
            <div className='main'>
                <h2 
                    className = 'title'
                    data-aos='sm-fade-up'
                >
                    Featured Projects
                </h2>
                <div className='card-group'>

                    <div className='card' data-aos='fade-up'>
                        <div className='card-info'>
                            <h3 
                                className = 'card-title'
                            >
                                Automated Eye Exam
                            </h3>
                            <p className = 'description'>
                                On-going medical research into ophthalmology:
                                currently undergoing clinical trial with 
                                medical students. Numerous resources and 
                                frameworks like AWS, Alexa Skills Kit, Django, 
                                Nodejs, and more were used to handle the 
                                technical aspects of the project. 
                            </p>
                        </div>
                        <Link 
                            className = 'link'
                            to = '/eye_exam'
                        >
                            Learn More<i className='fas fa-chevron-right'/>
                        </Link>
                    </div>

                    <div className='card' data-aos='fade-up'>
                        <div className='card-info'>
                            <h3 
                                className = 'card-title'
                            >
                                Photometric Stereo
                            </h3>
                            <p className = 'description'>
                            A research project exploring methods of image 
                            modification in the field of computer vision using 
                            light, shadows, albedos, and photometric stereo on 
                            Lambertian objects. Python, iPython, Jupyter 
                            Notebook, LaTex, and Blender were used to write 
                            code and generate 3D models.

                            </p>
                        </div>
                        <Link 
                            className = 'link'
                            to = '/photometric_stereo'
                        >
                            Learn More<i className='fas fa-chevron-right'/>
                        </Link>
                    </div>

                    <div className='card' data-aos='fade-up'>
                        <div className='card-info'>
                            <h3 
                                className = 'card-title'
                            >
                                Operating System
                            </h3>
                            <p className = 'description'>
                                A lower level programming project focusing on
                                system calls and multi-threading. System calls
                                consist of fork, execv, getPID, etc. This was
                                completed in Unix environment using Unix OS
                                tools.
                            </p>
                        </div>
                        <Link 
                            className = 'link'
                            to = '/operating_system'
                        >
                            Learn More<i className='fas fa-chevron-right'/>
                        </Link>
                    </div>

                    
                    <div className='card' data-aos='fade-up'>
                        <div className='card-info'>
                            <h3 
                                className = 'card-title'
                            >
                                AI Classification
                            </h3>
                            <p className = 'description'>
                                An artificial intelligence project aimed at
                                training a model to classify a leaf based on
                                54 features. This is done through a 
                                mathematical approach to quantify the feature. 
                                Python and AI Library SKLearn.

                            </p>
                        </div>
                        <Link 
                            className = 'link'
                            to = '/ai_classification'
                        >
                            Learn More<i className='fas fa-chevron-right'/>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    );
}