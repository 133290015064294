import React from 'react';
import './navbar.scss';



export default class Navbar extends React.Component {

    componentDidMount() {
        const menuIconContainer = document.querySelector(
            'nav .menu-icon-container'
        );
        const navContainer = document.querySelector('.nav-container');
      
          menuIconContainer.addEventListener('click', () => {
            navContainer.classList.toggle('active');
          });
    }
    render() {
        return(
            <div className='nav-container'>
                <nav>
                    <ul className='mobile-nav'>
                        <li>
                            <div className='menu-icon-container'>
                                <div className='menu-icon'>
                                    <span className='line-1'></span>
                                    <span className='line-2'></span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <button
                                className='link-logo' 
                                onClick = {() => {scrollTo('landing-page')}}
                            />
                        </li>
                        <li></li>
                    </ul>

                    <ul className='desktop-nav'>
                        <li>
                            <button
                                className='link-logo' 
                                onClick = {() => {scrollTo('landing_page')}}
                            />
                        </li>
                        <li>
                            <button 
                                onClick = {() => {scrollTo('projects_page')}}
                            >
                                Projects
                            </button>
                        </li>
                        <li>
                            <a 
                                href = 'https://drive.google.com/file/d/13WmrvOtN7_qpT6IDK8hmKR5-Woak6JWX/view?usp=sharing'
                                target = '_blank'
                                rel='noreferrer'
                            >
                                Resume
                            </a>
                        </li>
                        <li>
                            <button 
                                onClick = {() => {scrollTo('about_page')}}
                            >
                                About
                            </button>
                        </li>
                        <li>
                            <button 
                                onClick = {() => {scrollTo('contact_page')}}
                            >
                                Contact
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

function scrollTo (elem){
    if (document.getElementById(elem) == null){
        document.location = '/';
        return;
    }
    document.getElementById(elem).scrollIntoView(true);
    const navContainer = document.querySelector('.nav-container');
    navContainer.classList.toggle('active'); 
}