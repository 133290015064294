import './contact_page.scss';


export default function contact_page(){
    return(
        <section className = 'contact_page container' id = 'contact_page'>
            <div className="main">
                <h3 className = 'title'>Contact Me</h3>
                <div className="contact_container">

                    <div className="social_container">
                        <h4 className = 'subtitle'>Socials</h4>
                        <ul className = 'socials'>
                            <li className = 'link'>
                                <a
                                    href = 'mailto:abdallahsolimen@gmail.com'
                                    target = '_blank'
                                    rel = 'noreferrer'
                                >
                                    <i className="fas fa-inbox"/>
                                    &nbsp;&nbsp;Email
                                </a>
                            </li>

                            <li className = 'link'>
                                <a
                                    href = 'https://www.linkedin.com/in/abdallahsoliman/'
                                    target = '_blank'
                                    rel = 'noreferrer'
                                >
                                    <i className="fab fa-linkedin-in"/>
                                    &nbsp;&nbsp;LinkedIn
                                </a>
                            </li>

                            <li className = 'link'>
                                <a
                                    href = 'https://github.com/Asolima'
                                    target = '_blank'
                                    rel = 'noreferrer'
                                >
                                    <i className="fab fa-github"/>
                                    &nbsp;&nbsp;GitHub
                                </a>
                            </li>

                            <li className = 'link'>
                                <a
                                    href = 'https://twitter.com/SolimenAbdallah'
                                    target = '_blank'
                                    rel = 'noreferrer'
                                >
                                    <i className="fab fa-twitter"/>
                                    &nbsp;&nbsp;Twitter
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="about_container">
                        <h4 className = 'subtitle'>About Site</h4>
                        <p className = 'text'>
                            This site was built with React.js, React Router DOM,
                            HTML, CSS, and Javascript.

                        </p>
                        <p className = 'text'>
                            If you have any questions or concerns please contact
                            me.
                        </p>
                        <br/>
                        <hr/>
                        <br/>
                        <p className = 'text'>
                            &copy; 2024 Abdallah Soliman.
                        </p>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}