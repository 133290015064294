import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import AOS from 'aos';

import './assets/fonts/SFProDisplay-Medium.ttf';
import './assets/fonts/SFProText-Light.ttf';
import './assets/fonts/SFProText-Regular.ttf';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

AOS.init({
  duration: 1000,
});
