import './alexa_page.scss';
import { Link } from 'react-router-dom';
export default function alexa_page() {
    return (
        <section className = 'alexa_page container'>
            <div className='main' data-aos = 'sm-fade-up'>
                <div className = 'title'>Automated&nbsp;
                    <span className = 'colorful'>Eye Exam</span>
                </div>
                <p className = 'paragraph'>
                   On-going research project conducted in collaboration with
                   physicians and medical students aiming to create an 
                   automated eye exam. The project was selected for clinical
                   trials and is currently undergoing clinical testing.
                   <br/><br/>
                   By automating eye exams, a small portion of health care
                   becomes accessible world wide. Health care is expensive and
                   difficult to distribute worldwide; this research project 
                   aims to address those constraints.
                </p>
                <div className='image'/>
                <h3 className = 'subtitle'>Technologies</h3>
                <p className = 'paragraph'>
                    Amazon Web Services (AWS), Alexa Skills Kit, Python, Django, and 
                    Raspberry Pi.
                </p>
                <h3 className = 'subtitle'>Role</h3>
                <p className = 'paragraph'>
                    Full Stack Developer
                </p>
                <h3 className = 'subtitle'>Want to learn more?</h3>
                <p className = 'paragraph'>
                    Due to an NDA, I cannot disclose details about my
                    experience with the project publicly. If you have any
                    questions feel free to contact me.
                </p>
                <div className='btn-group'>
                    <Link to = '/' className = 'btn'>
                        <i className="fas fa-home"/>
                        Home
                    </Link>
                    <Link to = '/photometric_stereo' className = 'btn'>
                        Next
                        <i className="fas fa-chevron-right"/>
                    </Link>
                </div>
                
            </div>
        </section>
    );
}