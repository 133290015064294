import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import './index.scss';

import MAIN_PAGE from './main_page';
import ALEXA_PAGE from './pages/alexa_page/alexa_page';
import CV_PAGE from './pages/photometric_page/photometric_page';
import OS_PAGE from './pages/os_page/os_page';
import DM_PAGE from './pages/dm_page/dm_page';

class App extends React.Component {

  render(){
    return (
      <Router>
        <ScrollToTop/>
        <Switch>
          <Route exact path = '/' component = {MAIN_PAGE}/>
          <Route exact path = '/eye_exam' component = {ALEXA_PAGE}/>
          <Route exact path = '/photometric_stereo' component = {CV_PAGE}/>
          <Route exact path = '/operating_system' component = {OS_PAGE}/>
          <Route exact path = '/ai_classification' component = {DM_PAGE}/>
        </Switch>
      </Router>
    );
  }
}

export default App;
