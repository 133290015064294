import React from 'react';

import './loading_screen.scss';

export default function loading_screen(){
    return (
        <div id="loading-wrapper">
            <div id="loading-content"/>
        </div>
    );
}