import './photometric_page.scss';
import { Link } from 'react-router-dom';
export default function photometric_page() {
    return (
        <section className = 'photometric_page container'>
            <div className='main' data-aos = 'sm-fade-up'>
                <div className = 'title'>Photometric&nbsp;
                    <span className = 'colorful'>Stereo</span>
                </div>
                <div className='paragraph_container'>
                    <p className = 'paragraph'>
                        A computer vision research project that explores light
                        manipulation. Photometric Stereo is the process of
                        estimating surface normals of Lambertian 
                        (non-reflective) objects by observing that object under
                        different light conditions. This is done through 
                        calculation of albedos, normals, and light direction. 
                        Those variables are used to control lighting of a scene.
                    </p>

                    <p className = 'paragraph'>
                        Computer Vision applies linear algebra concepts to 
                        evaluate/modify images. Matrices in linear algebra
                        translate to pixels in images, which is a crucial step 
                        for applying filters to images for instance. In every
                        aspect of computer vision, linear algebra is present —
                        from edge detection, to image modification operations.
                    </p>
                </div>
                <div className='image'/>
                <div className = 'paragraph'>
                    This project is a walkthrough and understanding of
                    different topics of photometric stereo. The first step was
                    to light a scene by applying a given albedos, normals, and
                    light direction. This is done by parsing the image pixels
                    and modifying its value using the formula below —
                    <br/><br/>
                    <p className='equation'>
                        image (𝑥) = Albedo (𝑥) [ 𝑙 * 𝑛 (𝑥) ]
                    </p>
                    <br/><br/>
                    Where Albedo (𝑥) is the albedo of a given pixel, 𝑙
                    is the light direction vector, and 𝑛 (𝑥) is the normals for
                    a given pixel. Using interactive python and user input, 
                    the scene lighting could be changed interactively.
                    <br/><br/>
                    However, albedos are not always given and sometimes need to
                    be calculated. The second step of this project is to 
                    calculate the albedos and normals. This is achieved by 
                    stacking an array of images of the same object under 
                    different light direction. For each pixel in the image, the
                    brightest value from the stack is taken. The brightest set 
                    of pixels is the albedo, since it would be the object with 
                    no shadows.
                </div>
                <h3 className = 'subtitle'>Challenges</h3>
                <p className = 'paragraph'>
                    Each image is different is there no efficient algorithmic 
                    way to anticipate every possible value. Sometimes a NaN 
                    (Not a Number) error occurs because the formula does not 
                    account for division by zero. To solve this, an edge case
                    had to be coded in.
                </p>
                <h3 className = 'subtitle'>Role</h3>
                <p className = 'paragraph'>
                    Back End Developer
                </p>
                <h3 className = 'subtitle'>Technologies</h3>
                <p className = 'paragraph'>
                    Interactive Python (iPython), Jupyter Notebook, Blender,
                    and LaTeX.
                </p>
                <h3 className = 'subtitle'>Want to learn more?</h3>
                <p className = 'paragraph'>
                    I wrote a small research paper on this topic. Read it down
                    below. If you have more questions or would like to learn
                    more reach out to me!
                </p>
                <br/> &nbsp;
                <a 
                    href='https://drive.google.com/file/d/1RHNHPDvfzYrZelSy4fgZgQq6RhH0ht2z/view' 
                    className = 'link'
                    target = '_blank'
                    rel = 'noreferrer'
                >
                    Research Paper
                    <i className='fas fa-chevron-right'/>
                </a>
                <div className='btn-group'>
                    <Link to = '/' className = 'btn'>
                        <i className='fas fa-home'/>
                        Home
                    </Link>
                    {/* <Link to = '/' className = 'btn'>
                        <i className='fas fa-home'/>
                        Home
                    </Link>
                    <a
                        href = 'https://github.com/Asolima'
                        className = 'btn'
                        target = '_blank'
                        rel = 'noreferrer'
                    >   
                        <i className='fas fa-book'></i>
                        Report
                    </a> */}
                    <Link to = '/operating_system' className = 'btn'>
                        Next
                        <i className='fas fa-chevron-right'/>
                    </Link>
                </div>
                
            </div>
        </section>
    );
}