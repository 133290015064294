import './os_page.scss';
import { Link } from 'react-router-dom';

export default function os_page() {
    return (
        <section className = 'os_page container'>
            <div className='main' data-aos = 'sm-fade-up'>
                <div className = 'title'>Operating&nbsp;
                    <span className = 'colorful'>System</span>
                </div>
                <p className = 'paragraph'>
                    A lower-level programming project that focuses on OS 
                    concepts (syncronization, multi-threading, etc.) and system 
                    calls (fork, execv, get_pid, etc.). The purpose is to build
                    a minimally operational OS system that can perform basic OS
                    commands such as file reads, run programs with command line
                    arguments, and basic shell commands. Most of the code for
                    this project is in C, however, some were written in
                    assembly.
                    <br/><br/>
                    The main system functions that were tasked were — 
                    sys_getPID, sys_getPPID, sys_fork, sys_waitPID, and 
                    sys_execv.
                </p>
                <div className='image'/>

                <div className = 'paragraph'>
                    <ul>
                        <li>
                            <p className = 'code'>
                                sys_getPID
                            </p>
                             
                            &nbsp;—&nbsp;gets the current process ID
                        </li>

                        <li>
                            <p className = 'code'>
                                sys_getPPID
                            </p>
                             
                            &nbsp;—&nbsp;gets the current process's parent ID
                        </li>

                        <li>
                            <p className = 'code'>
                                sys_fork
                            </p>
                             
                            &nbsp;—&nbsp;creates a new process by forking
                            the current process
                        </li>

                        <li>
                            <p className = 'code'>
                                sys_execv
                            </p>
                             
                            &nbsp;—&nbsp;executes a program file with optional
                            arguments and options
                        </li>

                        <li>
                            <p className = 'code'>
                                sys_waitPID
                            </p>
                             
                            &nbsp;—&nbsp;waits until process with given PID
                            changes status
                        </li>

                        <li>
                            <p className = 'code'>
                                sys_exit
                            </p>
                             
                            &nbsp;—&nbsp;terminates current process and returns
                            exit code
                        </li>

                    </ul>
                    <br/><br/>
                    
                    {/* <h3 className = 'subtitle'>The Design</h3>
                    
                    <p className = 'code'>
                        sys_getPID(
                            <p className = 'bold'>
                                pid_t&nbsp;
                            </p>
                            <p className = 'param'>
                                retval
                            </p>
                        )
                    </p>
                        
                    &nbsp;—
                    <br/><br/>
                    &emsp;&emsp;Return the current process's id from&nbsp;
                    <p className = 'bold' style={{color:'white'}}>
                        curthread-&gt;pid.
                    </p>

                    <br/><br/><br/>

                    <p className = 'code'>
                        sys_getPPID(
                            <p className = 'bold'>
                                pid_t&nbsp;
                            </p>
                            <p className = 'param'>
                                retval
                            </p>
                        )
                    </p>
                        
                    &nbsp;—
                    <br/><br/>
                    &emsp;&emsp;Return the current process's parent id.


                    <br/><br/><br/>

                    <p className = 'code'>
                        sys_fork(
                            <p className = 'bold'>
                                struct trapframe&nbsp;
                            </p>
                            <p className = 'param'>
                                *parent_tf,&nbsp;
                            </p>

                            <p className = 'bold'>
                                int32_t&nbsp;
                            </p>
                            <p className = 'param'>
                                ret
                            </p>
                            
                        )
                    </p>
                        
                    &nbsp;—
                    <br/><br/>
                    &emsp;&emsp;Take in the parent trapframe and return
                    value pointer. Use&nbsp;
                    <p className = 'bold' style={{color:'white'}}>
                        copyin()&nbsp;
                    </p>
                    to copy the parent's trapframe to child process. Then
                    return proper error message if&nbsp;
                    <p className = 'bold' style={{color:'white'}}>
                        copyin()&nbsp;
                    </p>
                    is unsuccessful. Then use&nbsp;
                    <p className = 'bold' style={{color:'white'}}>
                        copyin()&nbsp;
                    </p>
                    to copy parent's address space to child. Return an
                    error if&nbsp;
                    <p className = 'bold' style={{color:'white'}}>
                        copyin()&nbsp;
                    </p>
                    is unsuccessful. Call&nbsp;
                    <p className = 'bold' style={{color:'white'}}>
                        thread_fork()&nbsp;
                    </p>
                    to create the new child thread by executing&nbsp;
                    <p className = 'bold' style={{color:'white'}}>
                        md_forkentry()&nbsp;
                    </p>
                    taking in the child. The child process returns in
                    <p className = 'bold' style={{color:'white'}}>
                        md_forkentry().&nbsp;
                    </p>
                    Finally, return 0 on success. */}
                    
                </div>
                <h3 className = 'subtitle'>Role</h3>
                <p className = 'paragraph'>
                    Back End Developer
                </p>
                <h3 className = 'subtitle'>Technologies Used</h3>
                <p className = 'paragraph'>
                    C and Unix tools.
                </p>
                <h3 className = 'subtitle'>Want to learn more?</h3>
                <p className = 'paragraph'>
                    Contact me for any questions or check my GitHub for an
                    overview.
                </p>
                <div className='btn-group'>
                    <Link to = '/' className = 'btn'>
                        <i className="fas fa-home"/>
                        Home
                    </Link>
                    <Link to = '/ai_classification' className = 'btn'>
                        Next
                        <i className="fas fa-chevron-right"/>
                    </Link>
                </div>
                
            </div>
        </section>
    );
}