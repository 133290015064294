import './dm_page.scss';
import { Link } from 'react-router-dom';

export default function dm_page() {
    return (
        <section className = 'dm_page container'>
            <div className='main' data-aos = 'sm-fade-up'>
            <div className = 'title'>Leaf&nbsp;
                    <span className = 'colorful'>Classification</span>
                </div>
                <p className = 'paragraph'>
                    A leaf classification project that uses 54 physical
                    features (i.e. stem size, leaf width, etc.) to identify a
                    a leaf. High accuracy score of 96% was achieved using&nbsp;
                    <a 
                    href='https://en.wikipedia.org/wiki/K-nearest_neighbors_algorithm'
                    className='link'
                    target = '_blank'
                    rel='noreferrer'
                    >
                       KNN Algorithm
                    </a>
                    , fine tuning hyperparameters, and cross validation. Cross
                    validation included two other algorithm to test for highest
                    accuracy: Decision Tree, Naive Bayes. KNN yielded the
                    highest accuracy using euclidean distance as a metric and a
                    K value of 1.
                    <br/><br/>
                </p>
                <div className='image'/>
                <h3 className = 'subtitle'>The Process</h3>
                <p className = 'paragraph'>
                    The dataset was provided by&nbsp;
                    <a 
                        href='https://cs.gmu.edu'
                        className='link'
                        target='_blank'
                        rel='noreferrer'
                    >
                        GMU CS Department
                    </a>
                    . The first step was to read in the data and clean it up. 
                    I utilized Pandas Python Library in order to read in the 
                    dataset. In order to keep the code legible and modular I 
                    split up the work into multiple simple helper functions
                    (e.g. functions to read in data, process it, etc.).
                    <br/><br/>
                    After working with the data for a while I discovered that
                    the dataset was imbalanced and that greatly affected the
                    accuracy. The dataset would need to be resampled more
                    evenly in order for the module to achieve higher accuracy.
                    Later in the cross validation phase, I would modify this
                    function to both upsample and downsample.
                    <br/><br/>
                    Then came the cross validation, which is usually the most
                    intensive part of AI projects. In order to properly cross
                    validate and fine tune hyperparamters, one must know what
                    each hyperparamter is and how changing the value affects
                    the data. The hyperparamters I changed for KNN are
                    n_neighbors, weights, metric, resampling, upsampling,
                    KFold, and PCA.
                    <br/><br/>
                    <ul>
                        <li>
                            <i>n_neighbors</i> — the number of neighbors used.
                        </li>
                        <li>
                            <i>weights</i> — the weight function used.
                        </li>
                        <li>
                            <i>metric</i> — the distance metric between neighbors
                        </li>
                        <li>
                            <i>resampling</i> — whether or not to resample data
                        </li>
                        <li>
                            <i>upsampling</i> — whether to up or down sample
                        </li>
                        <li>
                            <i>KFold</i> — whether or not to use KFold
                        </li>
                        <li>
                            <i>PCA</i> — whether or not to use PCA
                        </li>
                    </ul>
                    <br/><br/>
                    The best paramters ended up being &#123;'n_neighbors': 1, 
                    'weights': 'distance', 'metric': 'euclidean', 'resampling':
                    True, 'upsampling': True, 'KFold': False, 'PCA': False&#125;.
                    The runtime for cross validation was about 31 
                    minutes.
                </p>
                <h3 className = 'subtitle'>Role</h3>
                <p className = 'paragraph'>
                    Back End Developer
                </p>
                <h3 className = 'subtitle'>Want to learn more?</h3>
                <p className = 'paragraph'>
                    Reach out to me for any specific question or check the
                    project repository in GitHub!
                </p>
                <div className='btn-group'>
                    <Link to = '/' className = 'btn'>
                        <i className="fas fa-home"/>
                        Home
                    </Link>
                </div>
                
            </div>
        </section>
    );
}